





























































































import TypeSelect from './TypeSelect.vue'
import Editor from '@/components/Editor.vue'
import TextCheckbox from './components/TextCheckbox.vue'
import { EntityType, ProjectFormType } from '@/api/project/model'
import { UPDATE_SESSION_MESSAGE_ENTITY, UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS } from '@/config/event-bus'
import { useEventBus } from '@/hooks/useEventBus'
import { defineComponent, inject, ref ,watch,onMounted} from '@vue/composition-api'
import { Checkbox, CheckboxGroup, Popover } from 'element-ui'
import TimeCustomSelect from '@/components/TimeCustomSelect.vue'
import _ from 'lodash'
import SettingBox from '@/components/SettingBox.vue'

export default defineComponent({
    components: {
        TypeSelect,
        Editor,
        TimeCustomSelect,
        TextCheckbox,
        [Popover.name]: Popover,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        SettingBox,
    },
    setup(props, { refs }) {
        const { busEmit, busOn } = useEventBus()
        const itemObj: any = inject('itemObj')
        const branchActiveIndex = ref(0)
        const addBranch = async () => {
            const data = {
                name: '--',
                message_entity_id: itemObj.message_entity.id,
                options: [],
                content: ''
            }
            itemObj.message_entity.branchs.push(data)
            onChange()
        }

        const deleteBranch = async (index: number) => {
            ;(<any>refs.popoverRef)[index].doClose()
            branchActiveIndex.value = 0
            itemObj.message_entity.branchs.splice(index, 1)
            onChange()
        }

        const copyContentToAllBranch = async () => {
            const copyContent = itemObj.message_entity.branchs[branchActiveIndex.value].content
            itemObj.message_entity.branchs.forEach((branch: any) => {
                branch.content = copyContent
            })
            onChange()
        }

        const chooseOption = async (item: any, obj: any) => {
            const isAdd = item.options.findIndex((option: any) => option.message_option_id === obj.id) === -1
            if (isAdd) {
                item.options.push({
                    message_option_id: obj.id
                })
            } else {
                const index = item.options.findIndex((option: any) => option.message_option_id === obj.id)
                item.options.splice(index, 1)
            }
            const name = itemObj.message_entity.parent_message_entity.options
                .filter((v: any) => item.options.map((r: any) => r.message_option_id).includes(v.id))
                .map((z: any) => z.text)
                .toString()
            item.name = name || '--'
            onChange()
        }

        const handleParentOptionList = (optionItem: any, index: number) => {
            const parentOptionAry: any[] = []
            const otherOption = itemObj.message_entity.branchs.filter((v: any, i: number) => i != index)
            const otherList: any[] = []
            otherOption.forEach((v: any) => {
                otherList.push(...v.options)
            })

            const handleOther = (item: any) => {
                return otherList.some((v: any) => v.message_option_id == item.id)
            }

            const handleLength = (item: any) => {



                return false
            }

            itemObj.message_entity.parent_message_entity.options.forEach((item: any) => {
                const info = {
                    ...item,
                    disabled: handleLength(item) || handleOther(item)
                }
                parentOptionAry.push(info)
            })

            return parentOptionAry
        }

        const optionIsCheck = (item: any, obj: any) => {
            for (let i = 0; i < item.options.length; i++) {
                if (item.options[i].message_option_id == obj.id) {
                    return true
                }
            }
            return false
        }

        enum OptionName {
            A,
            B,
            C
        }

        const onChange = () => {
            localStorage.setItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`, JSON.stringify(itemObj));
            busEmit(UPDATE_SESSION_MESSAGE_ENTITY, itemObj)
        }

        const onBusOn = () => {
            
            busOn(UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS, (data: any) => {
                const { message_entity, id } = data
                if (id === itemObj.id) {
                    // Object.keys(itemObj.message_entity).forEach((key) => {
                    //     if (key != 'content') {
                    //         itemObj.message_entity[key] = message_entity[key]
                    //     }
                    // })
                    const storedItem = localStorage.getItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
                    if (storedItem) {
                        const { message_entity, id } = JSON.parse(storedItem)
                        // 将本地存储的值设置回输入框
                        Object.keys(itemObj.message_entity).forEach((key) => {
                                itemObj.message_entity[key] = message_entity[key]
                        })
                    }
                    localStorage.removeItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
                    }
            })
        }
        onBusOn()

        const setIsCollapse = ref(true)
        onMounted(() => {
            const storedItem = localStorage.getItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
            if (storedItem) {
                const { message_entity, id } = JSON.parse(storedItem)
                Object.keys(itemObj.message_entity).forEach((key) => {
                        itemObj.message_entity[key] = message_entity[key]
                        onChange()
                })
            }
        });
        return {
            ProjectFormType,
            EntityType,
            addBranch,
            deleteBranch,
            chooseOption,
            optionIsCheck,
            OptionName,
            handleParentOptionList,
            copyContentToAllBranch,
            itemObj,
            onChange,
            branchActiveIndex,
            setIsCollapse,
        }
    }
})
