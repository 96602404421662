






























import { defineComponent, inject, watch,onMounted } from '@vue/composition-api'
import TypeSelect from './TypeSelect.vue'
import Editor from '@/components/Editor.vue'
import TextCheckbox from './components/TextCheckbox.vue'
import { EntityType, ProjectFormType } from '@/api/project/model'
import { useEventBus } from '@/hooks/useEventBus'
import { UPDATE_SESSION_MESSAGE_ENTITY, UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS } from '@/config/event-bus'
import TimeCustomSelect from '@/components/TimeCustomSelect.vue'
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'
import { useProjectStore } from '@/pinia/modules/project'
export default defineComponent({
    components: {
        TypeSelect,
        Editor,
        TextCheckbox,
        TimeCustomSelect
    },
    setup(props, { root }) {
        const { busEmit, busOn } = useEventBus()
        const itemObj: any = inject('itemObj')
        const projectStore = useProjectStore()
        const { projectInfo } = storeToRefs(projectStore)

        const onChange = () => {
            localStorage.setItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`, JSON.stringify(itemObj));
            busEmit(UPDATE_SESSION_MESSAGE_ENTITY, itemObj)
        }

        let isUpdate = false

        busOn(UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS, (data: any) => {
            const { message_entity, id } = data
            if (id === itemObj.id) {
                isUpdate = true
                // Object.keys(itemObj.message_entity).forEach((key) => {
                //     if (key != 'content') {
                //         itemObj.message_entity[key] = message_entity[key]
                //     }
                // })
                const storedItem = localStorage.getItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
                if (storedItem) {
                    const { message_entity, id } = JSON.parse(storedItem)
                    // 将本地存储的值设置回输入框
                    Object.keys(itemObj.message_entity).forEach((key) => {
                            itemObj.message_entity[key] = message_entity[key]
                    })
                }
                localStorage.removeItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
            }
        })

        watch(
            () => itemObj.message_entity.settings,
            (newValue, oldValue) => {
                if (isUpdate) {
                    isUpdate = false
                    return
                }
                const options = newValue[0]?.options
                if (options) {
                    const optionsCopy = cloneDeep(itemObj.message_entity.options)
                    itemObj.message_entity.options = itemObj.message_entity.options.filter((item: any) => !item.is_system)
                    if (options.other) {
                        const opt = optionsCopy.find((v: any) => v.is_system && v.option_type == 'other')
                        itemObj.message_entity.options.push(
                            opt
                                ? opt
                                : {
                                      is_system: true,
                                      text: root.$t('projectSurvey.optionType.other', projectInfo.value.detail_language),
                                      is_show: true,
                                      option_type: 'other'
                                  }
                        )
                    }
                    if (options.none) {
                        const opt = optionsCopy.find((v: any) => v.is_system && v.option_type == 'none')
                        itemObj.message_entity.options.push(
                            opt
                                ? opt
                                : {
                                      is_system: true,
                                      text: root.$t('projectSurvey.optionType.none', projectInfo.value.detail_language),
                                      is_show: true,
                                      option_type: 'none'
                                  }
                        )
                    }
                }
                onChange()
            },
            {
                deep: true
            }
        )

        watch(
            () => itemObj.message_entity.content,
            () => onChange()
        )

        onMounted(() => {
            const storedItem = localStorage.getItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
            if (storedItem) {
                const { message_entity, id } = JSON.parse(storedItem)
                Object.keys(itemObj.message_entity).forEach((key) => {
                        itemObj.message_entity[key] = message_entity[key]
                        onChange()
                })
            }
        });
        return {
            ProjectFormType,
            EntityType,
            itemObj,
            onChange
        }
    }
})
